import 'ant-design-vue/lib/style/reset.css';
import 'ant-design-vue/lib/locale/ja_JP';
import '@/../less/global.less';
import '@/../less/design-component.less';
import '@/../less/trabox-ant.less';
import '@/../less/trabox-theme.less';
import { createApp } from 'vue';
import { install as vueCookies } from 'vue-cookies';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faBookmark as farBookmark,
    faBuilding as farBuilding,
    faClone as farClone,
    faHandshake as farHandshake,
} from '@fortawesome/free-regular-svg-icons';
import {
    faBookmark as fasBookmark,
    faExternalLinkAlt as faExternalLinkAlt,
    faPhoneAlt as fasPhoneAlt,
    faUser as fasUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import router from './router';
import App from './App.vue';
import { embedTag } from './vue-plugins/embed-tag';
import { microCms } from './vue-plugins/microcms-tag';

const app = createApp(App);

// Vue Base Plugins
app.use(router);
app.use(vueCookies);

import Antd from 'ant-design-vue';
app.use(Antd);

// Fontawesome
library.add(
    farBookmark,
    farBuilding,
    farClone,
    fasBookmark,
    fasPhoneAlt,
    farHandshake,
    fasUser,
    faExternalLinkAlt,
);
app.component('FontAwesomeIcon', FontAwesomeIcon);

app.use(embedTag);
app.use(microCms);

// Bootstrap App
app.mount('#app');
